<template>
  <div>
    <div class="sctp-card">
      <div class="sctp-card-header">
        <div class="sctp-card-title">确认订单信息</div>
      </div>
      <div class="sctp-card-body">
        <div>
          <div class="sctp-pad-lr15 sctp-pad-tb5 sctp-flex sctp-flex-sb">
            <div class="sctp-flex sctp-flex-aic">
              <el-image
                style="height: 25px;width: 25px;"
                fit="cover"
                :src="shopInfo && shopInfo.logo"
                class="sctp-mar-r10"
              >
                <div slot="error" class="image-slot">
                  <el-image
                    style="height: 25px;width: 25px;"
                    fit="cover"
                    :src="DEFAULT_AVATAR_PATH"
                  ></el-image>
                </div>
              </el-image>
              店铺：{{ shopInfo && shopInfo.shopname || ' ' }}
            </div>
            <div>小计</div>
          </div>
          <div class="sctp-pad-lr15 sctp-pad-tb10 sctp-flex sctp-flex-sb">
            <div class="sctp-flex sctp-flex-aic">
              <el-image
                style="height: 60px;width: 60px;"
                fit="cover"
                :src="service && service.servicecover && service.servicecovers[0]"
                class="sctp-mar-r10"
              ></el-image>
              {{ service && service.servicename }}
            </div>
            <div class="sctp-flex sctp-flex-aic">
              ¥{{ service && service.serviceprice }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sctp-card">
      <div class="sctp-card-header">
        <div class="sctp-card-title">购买需求</div>
      </div>
      <div class="sctp-card-body">
        <el-form :model="formData" status-icon :rules="formRules" ref="form" label-width="120px"
                 size="small">
          <el-form-item label="期望交付时间" prop="date">
            <el-date-picker
              type="date"
              :picker-options="pickerOptions"
              v-model="formData.date"
              placeholder="选择日期">
            </el-date-picker>
            <template v-if="diffDay(formData.date)">
              {{ diffDay(formData.date) }}天
            </template>
          </el-form-item>
          <el-form-item style="width: 50%" label="预算费用" prop="budget">
            <el-input
              v-model="formData.budget"
            >
              <template slot="append">(单位：元)</template>
            </el-input>
          </el-form-item>
          <el-form-item label="附加要求">
            <el-input
              type="textarea"
              :rows="5"
              v-model="formData.remark"
              placeholder="请输入内容">
            </el-input>
          </el-form-item>
          <el-form-item label="附件">
            <el-upload
              :http-request="fileUploadFunction"
              action=""
              :data="{...uploadConfig.uploadEnum.SERVICE}"
              :limit="3"
              drag
              :on-exceed="uploadConfig.fileExceed"
              :on-error="uploadConfig.uploadFileError"
              :multiple="true"
              :on-success="uploadConfig.uploadSuccess.bind(null, {key: 'annex'})"
              :before-upload="uploadConfig.beforeUpload.bind(null, {key: 'annex'})"
              :on-remove="uploadConfig.onRemove.bind(null,  {key: 'annex'})"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              <div class="el-upload__tip" slot="tip">
                <div>提示：</div>
                <div>1.限制文件最大10M</div>
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item style="width: 50%" label="确认联系方式" prop="phone">
            <el-input
              type="phone"
              placeholder="请输入手机号"
              v-model="formData.phone"
              readonly
              clearable><span
              @click="changePhoneDialogConfig.showChangePhoneDialog = true"
              slot="append">更换手机号</span>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              size="small"
              @click="onSubmitFormClick('form')"
              :loading="!formData.canSubmitClick"
            >
              提交
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <el-dialog
      title="更换手机号"
      :visible.sync="changePhoneDialogConfig.showChangePhoneDialog"
      width="30%"
      center>
      <el-form
        :ref="changePhoneDialogConfig.formRefName"
        :model="changePhoneDialogConfig.changePhoneForm"
      >
        <el-form-item
          prop="phone"
          :rules="[
                { required: true, message: '请输入手机号', trigger: 'blur' },
                { validator: validator.validPhone, message: '手机号格式不正确', trigger: 'blur' }
              ]"
        >
          <el-input
            placeholder="请输入手机号" v-model="changePhoneDialogConfig.changePhoneForm.phone"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="code"
          :rules="[
              { required: true, message: '请输入验证码', trigger: 'blur' },
              { length: 6, message: '请输入六位', trigger: 'blur' },
            ]"
        >
          <el-input
            placeholder="请输入验证码" v-model="changePhoneDialogConfig.changePhoneForm.code"
          >
            <el-button
              :loading="!changePhoneDialogConfig.canClickSendBtn"
              @click="changePhoneDialogConfig.onSendCodeClick" slot="append"
            >
              <template v-if="changePhoneDialogConfig.canClickSendBtn">
                {{ changePhoneDialogConfig.sendCodeTipsText }}
              </template>
              <count-down v-else @finish="changePhoneDialogConfig.onCountDownFinish" format="ss秒后重试"
                          :time="60 * 1000"></count-down>
            </el-button>
          </el-input>
          <div ref="captcha" class="captcha"></div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary" @click="changePhoneDialogConfig.onSubmitClick"
        >
          确 定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import validator from "@/resources/js/async-validator";
import {differenceInCalendarDays} from "date-fns";
import {captcha, service} from "@/apis/index";
import uploadEnum from "@/resources/js/uploadenum";

const config = require('../../../resources/js/config');
require('@ASSET/libs/jigsaw/jigsaw.min');

export default {
  components: {
    countDown: () => import('@CMP/common/CountDown'),
  },
  name: "buy",
  data() {
    return {
      validator,
      serviceId: null,
      service: null,
      shopInfo: null,
      pickerOptions: {
        disabledDate: (date) => {
          let now = new Date();
          let curr = new Date(date)
          return differenceInCalendarDays(curr, now) < 1;
        }
      },
      formData: {
        date: null,
        budget: null,
        remark: null,
        annex: null,
        phone: null,
        canSubmitClick: true,
      },
      formRules: {
        date: [
          {required: true, message: '请选择日期', trigger: ['change', 'blur']}
        ],
        budget: [
          {required: true, message: '请输入预算金额', trigger: ['change', 'blur']},
          {validator: validator.validMoney, message: '金额不合法', trigger: ['change', 'blur']},
        ],
        phone: [
          {required: true, message: '请输入联系方式', trigger: ['change', 'blur']},
          {validator: validator.validPhone, message: '手机号格式不正确', trigger: ['change', 'blur']}
        ]
      },
      changePhoneDialogConfig: {
        changePhoneForm: {
          phone: null,
          code: null
        },
        formRefName: 'changePhoneForm',
        showChangePhoneDialog: false,
        sendCodeTipsText: '获取验证码',
        canClickSendBtn: true,
        onSendCodeClick: () => {
          this.$refs[this.changePhoneDialogConfig.formRefName]
          .validateField('phone', res => {
            if (!res) {
              this.changePhoneDialogConfig.initCaptcha({
                onSuccess: this.changePhoneDialogConfig.doSendCode,
              });
              return
            }
            this.changePhoneDialogConfig.canClickSendBtn = true
          })
        },
        initCaptcha: ({onSuccess, onFail}) => {
          window.jigsaw.init({
            el: this.$refs.captcha,
            onSuccess: () => {
              this.$refs.captcha.innerHTML = '';
              if (onSuccess) {
                onSuccess();
              }
            },
            onFail: () => {
              if (onFail) {
                onFail();
              }
            },
            onRefresh: () => {
            }
          })
        },
        doSendCode: () => {
          this.changePhoneDialogConfig.canClickSendBtn = false;
          captcha.sendPhoneCode({
            phone: this.changePhoneDialogConfig.changePhoneForm.phone
          }).then(res => {
            this.$message.success('发送成功，请注意查收!')
          }).catch(res => {
            this.changePhoneDialogConfig.canClickSendBtn = true
          })
        },
        onSubmitClick: () => {
          this.$refs[this.changePhoneDialogConfig.formRefName]
          .validate(res => {
            if (res) {
              this.changePhoneDialogConfig.doCheckCode()
            }
          })
        },
        doCheckCode: () => {
          const {phone, code} = this.changePhoneDialogConfig.changePhoneForm;
          captcha.checkPhoneCode({
            phone: phone,
            code: code
          }).then(res => {
            this.formData.phone = phone;
            this.changePhoneDialogConfig.showChangePhoneDialog = false;
            this.$message.success('更换成功!')
          })
        },
        onCountDownFinish: () => {
          this.changePhoneDialogConfig.canClickSendBtn = true;
          this.changePhoneDialogConfig.sendCodeTipsText = '重新获取'
        }
      },
      uploadConfig: {
        uploadEnum,
        uploadSuccess: ({key}, response, file, fileList) => {
          if (key === 'annex') {
            this.formData[key] = fileList.map(item => item.response.src || item.url).join(',');
          }
          this.$message.success('文件上传成功！');
        },
        onRemove: ({key}, file, fileList) => {
          if (key === 'annex') {
            this.formData[key] = fileList.map(item => item.response.src || item.url).join(',');
          }
        },
        beforeUpload: ({key}, file) => {
          let result = false;
          if (key === 'annex') {
            const isLtSize = file.size / 1024 / 1024 < 10;
            if (!isLtSize) {
              this.$message.error(`上传文件大小不能超过 10MB!`);
              return false;
            }
            result = isLtSize;
          }
          return result && this.$message('文件正在上传') && true;
        },
        fileExceed() {
          this.$message.warning(`文件数量已达上限！`);
        },
        uploadFileError() {
          this.$message.error('文件上传失败')
        },
      },
    }
  },
  methods: {
    onSubmitFormClick(formName) {
      this.$refs[formName].validate()
      .then(res => {
        this.formData.canSubmitClick = false
        this.doSubmitForm().finally(() => {
          this.formData.canSubmitClick = true
        })
      })
      .catch(() => {
        this.$message.warning('请完善表单')
      })
    },
    setDefaultPhone() {
      if (this.user) {
        if (this.user.phone) {
          this.formData.phone = this.user.phone
        }
      }
    },
    doSubmitForm() {
      const {formData, serviceId} = this
      return service.submitOrder({
        userId: this.user.userId,
        serviceId: serviceId,
        expectDate: formData.date,
        phone: formData.phone,
        annex: formData.annex,
        remark: formData.remark,
        budget: formData.budget,
      }).then(res => {
        this.$message.success('提交成功');
        let {data} = res;
        this.goPage({
          path: '/service/buy/signingContract',
          name: '服务签订合同',
          query: {
            orderId: data
          }
        })
      });
    },
    getInfoByServiceId() {
      if (!this.serviceId) {
        return
      }
      service.serviceInfo({
        id: this.serviceId,
        userId: this.user.userId,
        invalid_cache: new Date().getTime(),
      }).then(res => {
        const {data, shopInfo} = res
        data.servicecover = data.servicecover ? data.servicecover.split(',') : ''
        this.service = data;
        this.shopInfo = shopInfo;
      })
    }
  },
  computed: {
    diffDay() {
      return function (date) {
        return differenceInCalendarDays(date, new Date()) || '';
      }
    }
  },
  beforeMount() {
    let {query: {serviceId}} = this.$route;
    if (serviceId) {
      this.serviceId = serviceId
      this.getInfoByServiceId();
      this.setDefaultPhone();
    } else {
      this.goPage('/');
    }
  }
}
</script>

<style scoped lang="scss">
.sctp-card {
  margin-top: 15px;
}

.sctp-card-header {
  padding: 10px 15px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.sctp-card-title {
  color: #F90;
  font-weight: bold;
  padding-left: 10px;
  border-left: 3px solid #F90;
}

.sctp-card-value > .option-item {
  margin: 10px;
}

.captcha {
  position: absolute !important;
  bottom: 0;
  right: 0;
  background-color: #FFF;
  box-shadow: 0 0 10px 1px #ccc;
  z-index: 1;
}
</style>
